export const getEstadoTareas = async(token) => {
    const response = await fetch('api/EstadosTareas', {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}