// import React, { Component } from 'react';
// import { Route } from 'react-router';
// import { Layout } from './components/Layout';
// import { Home } from './components/Home';
// import { FetchData } from './components/FetchData';
// import { Counter } from './components/Counter';

// import './custom.css'
// import LoginButton from './components/LoginButton';
// import LogoutButton from './components/LogoutButton';
// import Profile from './components/Profile';
// // import PrivateRoute from './components/common/PrivateRoute';

// export default class App extends Component {
//   static displayName = App.name;

//   render () {
//     return (
//       <Layout>
//         <Route exact path='/' component={Home} />
//         <Route path='/counter' component={Counter} />
//         {/* <PrivateRoute path='/fetch-data' component={FetchData} /> */}
//         {/* <Route path='/fetch-data' component={FetchData} /> */}
//         <Route path='/login' component={LoginButton} />
//         <Route path='/logout' component={LogoutButton} />
//         <Route path='/profile' component={Profile} />
//       </Layout>
//     );
//   }
// }







import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Layout } from './components/Layout';
import { Paper } from '@mui/material';

import { FetchData } from './components/FetchData';
import { ThemeProvider } from '@mui/material/styles';
import Home from './components/Home/index';
import Servicios from './components/Servicios';
import TipoTareas from './components/TipoTareas';
import Empresa from './components/Empresa';
import DatosEmpresa from './components/Empresa/datosEmpresa';
import Tareas from './components/Tareas';
import VisualizarObservaciones from './components/Tareas/visualizarObservaciones';
import Configuracion from './components/Configuracion';
import UsersSinEmpresa from './components/Home/usersSinEmpresa';

import PrivateRoute from './components/common/PrivateRoute';

import './custom.css'
import "moment/locale/es"

import { useAuth0 } from './react-auth0-spa';
import { getUsuarioEmpresa } from './helpers/empresa';


// THEME.
import { darkTheme, lightTheme } from './customTheme';

export default function App (){
    const [theme, setTheme] = useState('light');
    const [usuarioExiste, setUsuarioExiste] = useState(false);
    const { user, getTokenSilently } = useAuth0();
    const history = useHistory();
    

    useEffect(() => {
        const preferenciasTema = window.localStorage.getItem('tema');
        if(preferenciasTema) {
            setTheme(preferenciasTema);
            document.documentElement.style.setProperty('--tema', preferenciasTema);
            return
        }

        //// TEMA POR DEFECTO DEL NAVAGADOR.
        if (matchMedia('(prefers-color-scheme: light)').matches) {
            setTheme('light');
            document.documentElement.style.setProperty('--tema', 'light');
        }
        else{
            setTheme('dark');
            document.documentElement.style.setProperty('--tema', 'dark');
        }

    }, []);

    useEffect(() => {
        if(!user) return

        async function ExisteUsuario() {
            const token = await getTokenSilently();
            const usuarioEncontrado = await getUsuarioEmpresa(user, token);
            setUsuarioExiste(usuarioEncontrado);
            if(!usuarioEncontrado) {
                history.push('userSinEmpresa')
            }
        }
        
        ExisteUsuario();
    }, [user])

    const toggleTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
            document.documentElement.style.setProperty('--tema', 'dark');
            window.localStorage.setItem('tema', 'dark')
            return
        }

        setTheme('light');
        document.documentElement.style.setProperty('--tema', 'light');
        window.localStorage.setItem('tema', 'light')
    }

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <Paper sx={{minHeight:'100vh'}}>
                <Layout toggleTheme={toggleTheme} showMenu={usuarioExiste}>
                    <PrivateRoute exact path='/empresa' component={Empresa} />
                    <PrivateRoute exact path='/empresa/datosEmpresa' component={DatosEmpresa} />
                    <PrivateRoute exact path='/tipoTareas' component={TipoTareas} />
                    <PrivateRoute exact path='/servicios' component={Servicios} />
                    <PrivateRoute exact path='/tareas' component={Tareas} />
                    <PrivateRoute exact path='/tareas/visualizarObservaciones' component={VisualizarObservaciones} />
                    <PrivateRoute exact path='/configuracion' component={Configuracion} />
                    <PrivateRoute path='/fetch-data' component={FetchData} />
                    <PrivateRoute exact path='/' component={Home} />
                </Layout>
                <PrivateRoute exact path='/userSinEmpresa' component={UsersSinEmpresa} />
            </Paper>

        </ThemeProvider>
    );
}