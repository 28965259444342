import React, { useState, useEffect } from "react"

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import Input from '@mui/material/Input';
// import { FormControl, InputLabel } from "@mui/material";
import moment from "moment";

import { postServicio, putServicio } from "../../helpers/servicios";
import FloatingActionButtons from "../fabButton";
import CheckIcon from '@mui/icons-material/Check';


import { useAuth0 } from "../../react-auth0-spa";


export default function NuevoServicio({ servicioSeleccionado, guardarServicio }) {
    const { user, getTokenSilently } = useAuth0();
    const [servicio, setServicio] = useState(servicioSeleccionado)
    
    const handleChange = (evento) => {
        const {name, value} = evento.target;
        setServicio({...servicio, [name]: value});
    }

    const guardar = async (e) => {
        e.preventDefault();
        const token = await getTokenSilently();

        if (servicio.servicioID > 0) {
            const result = await putServicio(servicio, user, token);
            guardarServicio(result, "PUT")
        }
        else {
            const result = await postServicio(servicio, user, token);
            guardarServicio(result, "POST")
        }
    }
    
    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            autoComplete="off"
            onSubmit={guardar}
        >
            
            <TextField name="titulo"
                required
                autoFocus
                label="Titulo"
                value={servicio.titulo}
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />
            
            <TextField name="fechaServicio" 
                required
                label="Fecha"
                type="datetime-local" 
                defaultValue={moment(servicio.fechaServicio).format("yyyy-MM-DDTHH:mm")}
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
            />
            
            {/* <TextField name="personaNombre" 
                label="Cliente" 
                value={servicio.personaNombre} 
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
            />

            {
                servicio.personaNombre !== '' &&
                <FormControl fullWidth variant="standard" margin="normal">
                    <InputLabel color="info" htmlFor="importe">Importe</InputLabel>
                    <Input
                        value={servicio.importe}
                        id="importe"
                        name="importe"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        fullWidth
                        onChange={handleChange}
                        color="info"
                    />
                </FormControl>
            } */}

            <FloatingActionButtons type="submit" color="success"><CheckIcon /></FloatingActionButtons>
        </Box>
    )
}