import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export default function CustomBasicCard({Titulo, TextoSecundario, Descripcion}) {
    return (
        <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardContent>
                <Typography sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
                    {TextoSecundario}
                </Typography>
                <Typography variant="h6" component="div">
                    {Titulo}
                </Typography>
                <Typography variant="body2" >
                    {Descripcion}
                </Typography>
            </CardContent>
            {/* <CardActions>
                <Button size="small">Ver más</Button>
            </CardActions> */}
        </Card>
    );
}
