import React, { useRef, useCallback, useEffect, useState } from "react"
import moment from "moment";
//Icons
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
//Components
import Button from '@mui/material/Button';
//Custom Components
import FloatingActionButtons from "../fabButton";
import FullScreenDialog from "../fullScreenDialog";
import CustomDialog from "../customDialog";
//Views
import NuevaTarea from "./nuevaTarea";
import Table from "./tabla";
import Panel from "./panel";

//Helpers
import { checkTarea, deleteTarea, enProcesoTarea, getTareasEstado, activarNotificacion } from "../../helpers/tareas";
import { getServicios } from "../../helpers/servicios";
import { getTipoTareas } from "../../helpers/tipoTareas";
import { getEstadoTareas } from "../../helpers/estadoTareas";
import { GetPermisoUsuarioActual } from "../../helpers/empresa";
import { getPermisosUsuarios } from "../../helpers/empresa";


import { useAuth0 } from "../../react-auth0-spa";


const labelEstadoTarea = (text) => text.split(" ").map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(" ");

export default function Tareas() {
    const { user, getTokenSilently } = useAuth0();
    const [state, setState] = useState({ loading: false, view: 'panel' });
    const initialCustomDialog = { show: false, title: "", text: "", buttons: [] };
    const [customDialog, setCustomDialog] = useState(initialCustomDialog);
    
    const [openDialog, setOpenDialog] = useState(false);

    const [tareas, setTareas] = useState([]);
    const [ultimaTareaEditada, setUltimaTareaEditada] = useState(0);
    
    const estadosTareas = useRef([]);
    const servicios = useRef([]);
    const tiposTareas = useRef([]);
    const esUsuarioTareas = useRef(false);
    const usuariosEmpresa = useRef([]);

    const initialState = {
        tareaID: 0,
        nombre: '',
        fechaTarea: moment().format("yyyy-MM-DDTHH:mm"),
        fechaTareaFin: moment().format("yyyy-MM-DDTHH:mm"),
        fechaCreacion: moment().format("yyyy-MM-DDTHH:mm"),
        servicioID: 0,
        tipoTareaID: 0,
        estadoTareaID: 1,
        detalle: '',
        observacionInterna: '',
        listadoUsuarios: []
    }
    const [tareaSeleccionada, setTareaSeleccionada] = useState(initialState)

    useEffect(() => {
        setState(prevState => ({ ...prevState, loading: true, view: window.localStorage.getItem('vista-tareas') || 'panel' }));

        async function InitialFetch() {
            try {
                const token = await getTokenSilently();
                const estadosTareasResponse = await getEstadoTareas(token);
                estadosTareas.current = estadosTareasResponse.map(estado => {
                    switch (estado.estadoTareaID) {
                        case 2: //EN PROCESO
                            return {...estado, label: labelEstadoTarea(estado.estadoTareaNombre), icon: <DoneIcon />, color: 'inProcessTask'}
                        case 3: //FINALIZADA
                            return {...estado, label: labelEstadoTarea(estado.estadoTareaNombre), icon: <DoneAllIcon />, color: 'finishedTask'}
                        default:
                            return {...estado, label: labelEstadoTarea(estado.estadoTareaNombre), icon: <AccessTimeIcon />, color: 'pendingTask'}
                    }
                })

                const permisoResponse = await GetPermisoUsuarioActual(user, token);
                esUsuarioTareas.current = permisoResponse.permisoID === 3;

                const usuariosEmpresaResponse = await getPermisosUsuarios(user, token);
                usuariosEmpresa.current = usuariosEmpresaResponse;

                const serviciosResponse = await getServicios(user, token);
                servicios.current = serviciosResponse;

                const tiposTareasResponse = await getTipoTareas(user, token);
                tiposTareas.current = tiposTareasResponse;

                setState(prevState => ({ ...prevState, loading: false }));

            } catch(error) {
                console.error('Ocurrio un error en Tareas - InitialFetch: \n', error);
            }
        }
        
        InitialFetch();
        
        return function() {
            estadosTareas.current = [];
            usuariosEmpresa.current = [];
            tiposTareas.current = [];
            servicios.current = [];
            esUsuarioTareas.current = false;
        }
    }, [])

    
    const handleOpenDialog = useCallback(() => setOpenDialog(true), []);

    const filtrarTareas = useCallback(async (estadoId = 0, fechaDesde, fechaHasta) => {
        try {
            const token = await getTokenSilently();
            const tareasResponse = await getTareasEstado(user, estadoId, fechaDesde, fechaHasta, token);
            setTareas(tareasResponse);
        } catch(error) {
            console.error('Ocurrio un error en Tareas - filtrarTareas: \n', error);
        }
    }, [])

    const handleClose = useCallback(() => {
        setOpenDialog(false)
        setTareaSeleccionada(initialState)
    }, [initialState]);

    const eliminarTarea = useCallback(tarea => {
        if(openDialog) {
            setOpenDialog(false)
        }

        const eliminar = async (tarea) => {
            const token = await getTokenSilently();
            const result = await deleteTarea(tarea, user, token);
            if (result === true) {
                guardarTarea(tarea, "DELETE")
            }
        }

        setCustomDialog({
            show: true,
            title: "Eliminar",
            text: "¿Está seguro que desea eliminar esta Tarea?",
            buttons: [
                <Button onClick={() => setCustomDialog(initialCustomDialog)}>Cancelar</Button>,
                <Button color="error" onClick={() => eliminar(tarea)}>Aceptar</Button>,
            ]
        });
    }, [initialCustomDialog])

    const finalizarTarea = useCallback((tarea, action = "DELETE") => {
        const enProceso = async (tarea) => {
            setCustomDialog(initialCustomDialog)

            const token = await getTokenSilently();
            const result = await enProcesoTarea(tarea, user, token);
            if (result === true) {
                guardarTarea({...tarea, estadoTareaID: 2, fechaInicio: moment().format(), usuarioInicioEmail: user.name }, action)
            }
        }
    
        const finalizar = async (tarea) => {
            setCustomDialog(initialCustomDialog)

            const token = await getTokenSilently();
            const result = await checkTarea(tarea, user, token);
            if (result === true) {
                guardarTarea({...tarea, estadoTareaID: 3, fechaFinalizacion: moment().format(), usuarioInicioEmail: user.name }, action)
            }
        }

        if (tarea.estadoTareaID === 1) {
            setCustomDialog({
                show: true,
                title: "Comenzar",
                text: "¿Está seguro que desea comenzar a realizar esta Tarea?",
                buttons: [
                    <Button onClick={() => setCustomDialog(initialCustomDialog)} >Cancelar</Button>,
                    <Button color="success" onClick={() => enProceso(tarea)}>Comenzar</Button>,
                ]
            });
        }

        if (tarea.estadoTareaID === 2) {
            setCustomDialog({
                show: true,
                title: "Finalizar",
                text: "¿Está seguro que desea finalizar esta Tarea?",
                buttons: [
                    <Button onClick={() => setCustomDialog(initialCustomDialog)}>Cancelar</Button>,
                    <Button color="success" onClick={() => finalizar(tarea)}>Finalizar</Button>,
                ]
            });
        }
    }, [initialCustomDialog])

    const editarTarea = tarea => {
        setTareaSeleccionada(tarea);
        setOpenDialog(true);
    };

    const guardarTarea = useCallback((tarea, action) => {
        setUltimaTareaEditada(tarea.tareaID);

        switch (action) {
            case "POST":
                setTareas([...tareas, tarea]);
                break;
            case "PUT":
                setTareas([...tareas.map(t => t.tareaID === tarea.tareaID ? { ...tarea } : t)]);
                break;
            case "DELETE":
                setTareas([...tareas.filter(t => t.tareaID !== tarea.tareaID)]);
                setCustomDialog(initialCustomDialog)
                break;
        }

        handleClose();

        setTimeout(() => setUltimaTareaEditada(0), 1500)
    }, [initialCustomDialog])

    const handleNotifications = useCallback(async (tarea, activar = true) => {     
        const token = await getTokenSilently();   
        const result = await activarNotificacion(tarea, user, token, activar);
        if(result) {
            setTareas(prevState => [...prevState.map(t => t.tareaID === tarea.tareaID ? { ...tarea, notificar: activar } : t)]);
        }
    }, [])
    
    return (
        <div>
            <h1 className="titulosVistas">Listado de Tareas</h1>
            <hr />
            {state.view === 'panel' && (
                <Panel 
                    lista={tareas} 
                    actions={{
                        editar: editarTarea,
                        filtrar: filtrarTareas
                    }}
                    esUsuarioTareas={esUsuarioTareas.current}
                    estadosTareas={estadosTareas.current}
                    servicios={servicios.current}
                    tiposTareas={tiposTareas.current}
                    usuariosEmpresa={usuariosEmpresa.current}
                />
            )}
            {state.view === 'table' && (
                <Table
                    headers={["Nombre", ""]} 
                    lista={tareas}
                    actions={{
                        editar: editarTarea,
                        eliminar: eliminarTarea,
                        finalizar: finalizarTarea,
                        notificar: handleNotifications,
                        filtrar: filtrarTareas
                    }}
                    ultimaTareaEditada={ultimaTareaEditada}
                    esUsuarioTareas={esUsuarioTareas.current}
                    estadosTareas={estadosTareas.current}
                />
            )}

            <FloatingActionButtons onClick={handleOpenDialog} color="primary" position="fixed" disabled={esUsuarioTareas.current || openDialog}>
                <AddIcon />
            </FloatingActionButtons>

            <CustomDialog {...customDialog} />

            <FullScreenDialog
                open={openDialog}
                handleClose={handleClose}
                titulo="Datos de la Tarea"
                actions={tareaSeleccionada.estadoTareaID !== 3 && tareaSeleccionada.tareaID !== 0 ? [
                    {
                        label: tareaSeleccionada.notificar ? 'Dejar de Notificar' : 'Notificar',
                        'aria-label': 'notify',
                        disabled: esUsuarioTareas.current || tareaSeleccionada.bloquearNotificar,
                        onClick: () => {
                            handleClose();
                            handleNotifications(tareaSeleccionada, !tareaSeleccionada.notificar);
                        },
                        color: 'primary',
                        variant: tareaSeleccionada.notificar ? 'contained' : 'outlined',
                        startIcon: tareaSeleccionada.notificar ? <NotificationsActiveIcon /> : <NotificationsNoneIcon />
                    },
                    {
                        label: 'Eliminar',
                        'aria-label': 'delete',
                        onClick: () => {
                            handleClose();
                            eliminarTarea(tareaSeleccionada);
                        },
                        color: 'error',            
                        startIcon: <DeleteIcon />
                    },
                    {
                        label: tareaSeleccionada.estadoTareaID === 1 ? 'Comenzar' : 'Finalizar',
                        'aria-label': tareaSeleccionada.estadoTareaID === 1 ? 'start' : 'finish',
                        color: tareaSeleccionada.estadoTareaID === 1 ? 'inProcessTask' : 'finishedTask',
                        onClick: () => {
                            handleClose();
                            finalizarTarea(tareaSeleccionada, "PUT")
                        },
                        startIcon: tareaSeleccionada.estadoTareaID === 1 ? <DoneIcon /> : <DoneAllIcon />
                    }
                ] : null}
            >
                <NuevaTarea 
                    tareaSeleccionada={tareaSeleccionada} 
                    disabled={esUsuarioTareas.current}
                    guardar={guardarTarea}
                    estadosTareas={estadosTareas.current} 
                    servicios={servicios.current}
                    tiposTareas={tiposTareas.current}
                    usuariosEmpresa={usuariosEmpresa.current}
                />
            </FullScreenDialog>
        </div>
    )
}