import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField } from '@mui/material'
import { Box } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery';

import moment from "moment";
import CustomBasicCard from '../customBasicCard';
import { getTareasObservaciones } from '../../helpers/tareas';
import { getEstadoTareas } from '../../helpers/estadoTareas';


import { useAuth0 } from "../../react-auth0-spa";

export default function VisualizarObservaciones() {
    const { user, getTokenSilently } = useAuth0();
    const [estadoTarea, setEstadoTarea] = useState([]);
    const [tareas, setTareas] = useState([]);
    const [tareasFiltradas, setTareasFiltradas] = useState([]);
    const inputBuscarRef = useRef(null);

    const initialState = {
        fechaDesde: moment().format("yyyy-MM-DDTHH:mm"),
        fechaHasta: moment().format("yyyy-MM-DDTHH:mm"),
        estadoTareaID: 1,
        buscar: ''
    }

    const [tareaFiltrar, setTareaFiltrar] = useState(initialState)
    
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.up('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.up('sm'));

    
    useEffect(() => {
        obtenerTareas();
    }, [])
    const obtenerTareas = async () => {
        const token = await getTokenSilently();
        getEstadoTareas(token)
            .then(respuestaEstadoTareas => {
                setEstadoTarea(respuestaEstadoTareas)
            })
    };


    useEffect(() => {
        filtrarTareas();
    }, [tareaFiltrar])

    useEffect(() => {
        setTareasFiltradas([...tareas]);
    }, [tareas])

    
    const handleChange = (evento) => {
        const { value, name } = evento.target;
        setTareaFiltrar({...tareaFiltrar, [name]: value})
    }

    const filtrarTareas = async () => {
        const token = await getTokenSilently();
        getTareasObservaciones(user, tareaFiltrar, token)
            .then(respuestaTareas => {
                setTareas(respuestaTareas);
        })
    }

    const handleChangeFiltrar = () => {
        setTareasFiltradas(filtrarTareasTexto(inputBuscarRef.current.value));
    };
    
    const filtrarTareasTexto = useCallback((value = '') => {
        const tarea = 
            tareas.filter(tareaa => (value !== '' && tareaa.nombre.toLowerCase().includes(value.toLowerCase())) || value === '');

        return tarea
    }, [tareas])

    
    return (
        <div>
            <h1 className="titulosVistas">Observaciones Semanales</h1>
            <hr />
            <Box
                sx={{ display: 'flex', flexWrap: 'wrap' }}
                autoComplete="off"
            >
                <Grid container spacing={{ xs: 0, sm: 2 }}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size="small"
                            name="fechaDesde"
                            label="Fecha Desde"
                            type="datetime-local"
                            value={tareaFiltrar.fechaDesde}
                            variant="standard"
                            fullWidth
                            margin={matches_sm ? "normal" : "dense"}
                            onChange={handleChange}
                            color="info"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size="small"
                            name="fechaHasta"
                            label="Fecha Hasta"
                            type="datetime-local"
                            value={tareaFiltrar.fechaHasta}
                            variant="standard"
                            fullWidth
                            margin={matches_sm ? "normal" : "dense"}
                            onChange={handleChange}
                            color="info"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            name="estadoTareaID"
                            id="estadoTareaID"
                            select
                            label="Estado"
                            value={tareaFiltrar.estadoTareaID}
                            variant="standard"
                            margin={matches_sm ? "normal" : "dense"}
                            color="info"
                            onChange={handleChange}
                            fullWidth
                        >
                            {estadoTarea.map((option) => (
                                <MenuItem key={option.estadoTareaID} value={option.estadoTareaID}>
                                    {option.estadoTareaNombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <TextField
                            size="small"
                            autoComplete="off"
                            name="buscar"
                            label="Buscar"
                            variant="standard"
                            onKeyUp={handleChangeFiltrar}
                            margin={matches_sm ? "normal" : "dense"}
                            color="info"
                            inputProps={{ style: { textTransform: "uppercase" }, ref: inputBuscarRef }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>


            <Box
                sx={{ display: 'flex', flexWrap: 'wrap' }}
                autoComplete="off"
            >
                <Grid container spacing={{ xs: 1, sm: 2 }}>
                    {tareasFiltradas.map((option) => (
                        <Grid item
                            xs={12} md={6} lg={4}
                            margin={matches_sm ? "normal" : "dense"}
                            key={option.tareaID}
                        >
                            <CustomBasicCard Titulo={option.nombre} TextoSecundario={moment(option.fechaTarea).format("DD/MM/yyyy")} Descripcion={option.observacionInterna}></CustomBasicCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    )
}