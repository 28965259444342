import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {useTheme, useMediaQuery} from '@mui/material'

export const StyledTableCell = styled(TableCell)(({ theme }) => {
    const match_md = useMediaQuery(theme.breakpoints.up('md'));


    return {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: match_md ? 14 : 11,
        },
        '&.MuiTableCell-root.MuiTableCell-body': {
            padding: match_md ? theme.spacing(1) : 5,
        },
        '&.MuiTableCell-root.MuiTableCell-body .badge': {
            fontSize: match_md ? 10 : 8, 
            height: match_md ? 20 : 'auto',
            padding: match_md ? 'auto' : '3px 0',
            fontWeight: 400,
            maxWidth: 130
        },
    }
});

export const StyledTableRow = styled(TableRow)(({ theme }) => {
    return {
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        // '&.title': {
        //     backgroundColor: theme.palette.grey.agrupadosTabla,
        //     fontSize: 9,
        // },
        [`&.${tableRowClasses.root}.title`]: {
            backgroundColor: theme.palette.grey.agrupadosTabla,
            fontSize: 9,
        },
        '&.fondo-rojo-tabla': {
            backgroundColor: theme.palette.danger.main,
        },
        '&.tarea-finalizada': {
            filter: 'grayscale(1)',
            textDecoration: 'line-through',
            textDecorationColor: '#a1a1a1'
        }
    }
});

export default function CustomTable({headers, children}) {
    return (
        <>
            <TableContainer component={Paper} >
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => <StyledTableCell key={index}>{header}</StyledTableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ height: '6rem' }}></div>
        </>
    );
}