import React from 'react';

import './index.css'

import { Box, Paper, Typography, Button } from '@mui/material';
import { useAuth0 } from '../../react-auth0-spa';
import { registrarUsuarioAuth0, getUsuarioSinEmpresa } from '../../helpers/empresa';
import { useEffect, useState } from 'react';


export default function UsersSinEmpresa() {
    const { user, logout, getTokenSilently } = useAuth0();
    const [esUsuarioEmpresa, setEsUsuarioEmpresa] = useState(false);

    useEffect(() => {
        usuarioTieneEmpresa(user);      
    }, [])

    if(!user) return null

    const usuarioTieneEmpresa = async (user) => {
        const token = await getTokenSilently();
        getUsuarioSinEmpresa(user, token)
            .then(result => {
                setEsUsuarioEmpresa(result);
            })
    };

    const funcionRegistrarUsuarioAuth0 = async (user) => {
        const token = await getTokenSilently();
        //console.log(token);
        const result = await registrarUsuarioAuth0(user, token);
        if(result){
            setEsUsuarioEmpresa(true);
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <Paper sx={{ backgroundColor: 'theme.palette.primary.light', p: 2, maxWidth: 350, borderRadius: '10px' }} elevation={3}>
                <img src={user.picture} alt={user.name} style={{ height: 'auto', width: '100%', objectFit: 'cover', aspectRatio: '1/1', borderRadius: '10px' }} />
                <Box component="header" sx={{ my: 1 }}>
                    <Typography gutterBottom variant="h6">
                        {user.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <u>Email</u>: {user.email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <u>Id</u>: {user.sub}
                    </Typography>
                </Box>
                <Box component="section" sx={{ my: 1 }}>

                    {                        
                        !esUsuarioEmpresa ? 
                        <div>
                                <Typography variant="subtitle1" color="text.secondary">
                                    Esperar a ser asignado
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Para asociarse a una empresa presione el botón esperar a ser asignado y luego contáctese con su administrador para que le brinde acceso a la misma.
                                </Typography>
                        </div> :
                        <div>
                        <Typography variant="subtitle1" color="text.secondary">
                            Asociarse a una Empresa
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Para asociarse a una empresa contáctese con su administrador enviándole como información el email y el id que se muestran en esta vista para así ingresar al sistema.
                        </Typography>
                        </div>
                    }
            
                </Box>
                <Box component="footer" sx={{ my: 1 }}>
                    <Button onClick={logout}>Cerrar sesión</Button>
                    
                    {                        
                        !esUsuarioEmpresa && <Button onClick={() => funcionRegistrarUsuarioAuth0(user)}>Esperar a ser asignado</Button>
                    }
                  
                </Box>
            </Paper>
        </Box>
    )
}